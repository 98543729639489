import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDragControls, motion } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import Background from '../../../primitives/backgrounds';
import { breakpoints } from '../../../primitives/tokens';
import ShadowLogo from './shadow-logo.svg';
import { colors } from '../../../primitives/colors';
// eslint-disable-next-line import/no-cycle
import StyledLink from '../../../primitives/styled-link';
import { getLink } from '../../../primitives/link';
import SolutionsCard from './card';
import { useLanguage } from '../../../language/language-provider';

type SolutionsCarouselProps = Queries.SolutionsCarouselPropsFragment;

const SolutionsCarousel = ({ title, text, link }: SolutionsCarouselProps): JSX.Element => {
    const controls = useDragControls();
    const [isDragging, setIsDragging] = React.useState(false);
    const [pos, setPos] = React.useState<React.PointerEvent<HTMLDivElement>|null>(null);
    const { language } = useLanguage();

    const staticData = useStaticQuery<Queries.BenefitsGridItemsQuery>(graphql`
        query BenefitsGridItems {
            au: datoCmsBenefitsGrid(locale: "en-AU") {
                items {
                    title
                    image {
                        ...DatoCmsInternalAsset
                    }
                    richText {
                        blocks
                        links
                        value
                    }
                    quote {
                        blocks
                        links
                        value
                    }
                    link {
                        ...LinkWithTitleProps
                    }
                }
            }
            uk: datoCmsBenefitsGrid(locale: "en-GB") {
                items {
                    title
                    image {
                        ...DatoCmsInternalAsset
                    }
                    richText {
                        blocks
                        links
                        value
                    }
                    quote {
                        blocks
                        links
                        value
                    }
                    link {
                        ...LinkWithTitleProps
                    }
                }
            }
            us: datoCmsBenefitsGrid(locale: "en-US") {
                items {
                    title
                    image {
                        ...DatoCmsInternalAsset
                    }
                    richText {
                        blocks
                        links
                        value
                    }
                    quote {
                        blocks
                        links
                        value
                    }
                    link {
                        ...LinkWithTitleProps
                    }
                }
            }
        }
    `);

    const cards = staticData[language].items;

    const checkPos = (e: React.PointerEvent<HTMLDivElement>, link: string): void => {
        if (pos && link) {
            if (pos.screenX === e.screenX) {
                if (process.env.NODE_ENV !== 'development') {
                    navigate(link);
                }
            }
        }
    };

    const startDrag = (event: React.MouseEvent): void => {
        setIsDragging(true);
        controls.start(event);
    };

    const endDrag = (): void => {
        setIsDragging(false);
    };

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <Background
            breakpoints={{
                dt: {
                    gradient: 'orange',
                },
            }}
            css={{
                position: 'relative',
                paddingTop: '148px',
                overflowX: 'hidden',
                userSelect: 'none',
                [breakpoints.mb]: {
                    paddingTop: '96px',
                },
            }}
        >
            <ShadowLogo css={{
                position: 'absolute',
                right: '0',
                bottom: '128px',
                pointerEvents: 'none',
                [breakpoints.mb]: {
                    height: '100%',
                    maxWidth: '260px',
                    width: '100%',
                    top: '-23vh',
                    right: '-12vw',
                    bottom: 'unset',
                },
            }}
            />
            <Container css={{
                paddingBottom: '54px',
                [breakpoints.mb]: {
                    paddingBottom: '39px',
                },
            }}
            >
                <Typography
                    fontWeight="light"
                    color="shadesWhite"
                    fontSize={{
                        dt: 4053,
                        tb: 3234,
                        mb: 2429,
                    }}
                    css={{
                        [breakpoints.mb]: {
                            width: '60%',
                            maxWidth: '205px',
                        },
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    css={{
                        opacity: 0.8,
                        paddingTop: '16px',
                        [breakpoints.mb]: {
                            paddingTop: '10px',
                            width: '85%',
                            maxWidth: '310px',
                        },
                    }}
                    color="shadesWhite"
                    fontSize={{
                        dt: 1822,
                        mb: 1420,
                    }}
                >
                    {text}
                </Typography>
            </Container>
            <div
                ref={ref}
                css={{
                    position: 'relative',
                    paddingBottom: '54px',
                    [breakpoints.mb]: {
                        paddingBottom: '34px',
                    },
                }}
            >
                <div
                    css={{
                        position: 'relative',
                        width: '100%',
                        minHeight: '305px',
                        pointerEvents: 'all',
                        [breakpoints.mb]: {
                            minHeight: 'unset',
                        },
                    }}
                    onPointerDown={startDrag}
                    onPointerUp={endDrag}
                    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                >
                    <motion.div
                        css={{
                            minWidth: `calc(${691 * cards.length + 35 * (cards.length - 1)}px + max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw)) * 2)`,
                            display: 'flex',
                            paddingLeft: 'max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw))',
                            width: '100%',
                            [breakpoints.mb]: {
                                minWidth: `calc(${258 * cards.length + 24 * (cards.length - 1)}px + max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw)) * 2)`,
                                paddingLeft: 'max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw))',
                            },
                        }}
                        dragControls={controls}
                        dragConstraints={ref}
                        drag="x"
                    >
                        {cards.map((x, idx) => (
                            <div
                                css={{
                                    maxWidth: '691px',
                                    width: '100%',
                                    flex: 'none',
                                    marginRight: idx !== cards.length - 1 ? '35px' : '0',
                                    display: 'flex',
                                    [breakpoints.mb]: {
                                        maxWidth: '258px',
                                        marginRight: idx !== cards.length - 1 ? '24px' : '0',
                                    },
                                }}
                                onPointerDown={setPos}
                                onPointerUp={(e) => {
                                    checkPos(e, getLink(x?.link?.[0]?.link)?.link);
                                }}
                            >
                                <SolutionsCard key={x.title} {...x} />
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>
            {(link && link.length > 0) && (
                <Container css={{
                    paddingBottom: '58px',
                    [breakpoints.mb]: {
                        paddingBottom: '46px',
                    },
                    textAlign: 'center',
                }}
                >
                    <StyledLink
                        to={getLink(link?.[0]?.link)}
                        css={{
                            color: colors.shadesWhite,
                        }}
                    >
                        {link?.[0].title}
                    </StyledLink>
                </Container>
            )}
        </Background>
    );
};

export default SolutionsCarousel;

export const query = graphql`
    fragment SolutionsCarouselProps on DatoCmsSolutionsCarousel {
        title
        text
        link {
            ...LinkWithTitleProps
        }
    }
`;
